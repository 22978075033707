const projectStore = {
  namespaced: true,
  state: {
    currentProjectId: localStorage.getItem("currentProjectId") || null,
    currencySymbol: localStorage.getItem("currencySymbol") || null,
    breadcrumbs: [],
  },
  getters: {
    currentProject: state => state.currentProjectId,
    currencySymbol: state => state.currencySymbol
  },
  actions: {
    currentProject({ commit }, { projectId }) {
      commit("setCurrentProject", {
        projectId: projectId
      });
      //commit("set_Bread_crumbs", [1,2,3,4,5]);
    },
    currencySymbol({ commit }, currencySymbol) {
      commit("setCurrencySymbol", currencySymbol);
    },
    setBreadcrumbs({ commit }, breadcrumbs) {
      commit("set_Bread_crumbs", breadcrumbs);
    }
  },
  mutations: {
    setCurrentProject(state, payload) {
      state.currentProjectId = payload.projectId;
      localStorage.currentProjectId = state.currentProjectId;
    },
    setCurrencySymbol(state, payload) {
      state.currencySymbol = payload;
      localStorage.currencySymbol = state.currencySymbol;
    },
    set_Bread_crumbs(state, payload) {
      state.breadcrumbs = payload;
    }
  }
};

export default projectStore;
