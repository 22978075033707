import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";

import { split } from "apollo-link";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";

import jwt from "./utils/jwt.js";

// Instantiate required constructor fields
const httpEndpoint =
  process.env.VUE_APP_GRAPHQL_HTTP || "https://api-stg.flattlo.com/";

const wsEndpoint =
  process.env.VUE_APP_GRAPHQL_WS || "wss://api-stg.flattlo.com/";

const headerLink = setContext((_, previousContext) => {
  let headers = {
    ...previousContext.headers,
    "x-hasura-user-id": localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).id
      : null
  };
  if (localStorage.getItem("JWT")) {
    const payload = jwt.getPayload(localStorage.getItem("JWT"));
    if (jwt.doesJWTHasExpired(payload)) {
      localStorage.removeItem("JWT");
      localStorage.removeItem("user");
      window.location.href = "/login";
    } else {
      headers.Authorization = `Bearer ${localStorage.getItem("JWT")}`;
    }
  }
  return {
    headers
  };
});

const httpLink = createHttpLink({
  uri: httpEndpoint,
  headers: {
    "content-type": "application/json"
  }
});

const wsLink = new WebSocketLink({
  uri: wsEndpoint,
  options: {
    reconnect: true,
    connectionParams: {
      headers: {
        Authorization: localStorage.getItem("JWT")
          ? `Bearer ${localStorage.getItem("JWT")}`
          : null
      }
    }
  }
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const cache = new InMemoryCache();

const client = new ApolloClient({
  // Provide required constructor fields
  cache: cache,
  link: headerLink.concat(link),

  // Provide some optional constructor fields
  queryDeduplication: false,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache"
    },
    query: {
      fetchPolicy: "no-cache"
    }
  }
});


export default client;
