import router from "../router";

const mutations = {
  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu

  TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE(state, value) {
    state.isVerticalNavMenuActive = value;
  },
  TOGGLE_REDUCE_BUTTON(state, val) {
    state.reduceButton = val;
  },
  UPDATE_MAIN_LAYOUT_TYPE(state, val) {
    state.mainLayoutType = val;
  },
  UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN(state, val) {
    state.verticalNavMenuItemsMin = val;
  },
  UPDATE_VERTICAL_NAV_MENU_WIDTH(state, width) {
    state.verticalNavMenuWidth = width;
  },

  // ////////////////////////////////////////////
  // USUARIO DE FIREBASE
  // ////////////////////////////////////////////

  ACTUALIZACION_DE_PERFIL(state, payload) {
    const fieldForUpdate = payload.fieldForUpdate;
    const valueToAdd = payload.valueToAdd;
    state.AppActiveUser[fieldForUpdate] = valueToAdd;
  },

  REINICIO_DE_CONTRASENA(payload) {
    console.log(payload);
  },

  PERFIL_DESARROLLADORA(state, payload) {
    (state.CompanyProfile.id = payload.ID),
      (state.CompanyProfile.adminEmail = payload.Admin_Email),
      (state.CompanyProfile.adminName = payload.Admin_Name),
      (state.CompanyProfile.adminLastName = payload.Admin_LastName),
      (state.CompanyProfile.cEmail = payload.Email_Contacto),
      (state.CompanyProfile.cPhone = payload.Telefono),
      (state.CompanyProfile.cName = payload.Desarrolladora),
      (state.CompanyProfile.cWeb = payload.Website),
      (state.CompanyProfile.fUrl = payload.FacebookUrl),
      (state.CompanyProfile.iUrl = payload.InstagramUrl),
      (state.CompanyProfile.lUrl = payload.LinkedinUrl),
      (state.CompanyProfile.cDescription = payload.PerfilDesarrolladora);
    state.CompanyProfile.profileImg = payload.profileImg;
    state.CompanyProfile.profileHeaderImg = payload.profileHeaderImg;
  },

  ///Editar Usuario
  EDITAR_USUARIO(state, payload) {
    state.editUserID = payload;
  },

  ///Editar Nivel
  MOSTRAR_EDITAR_NIVEL(state, payload) {
    state.showEditModule = payload.showEdit;
    state.idLevelforEdit = payload.id;
  },

  //Editar Bodega
  EDITAR_BODEGA(state, payload) {
    state.showEditWarehouseModule = payload.showEdit;
    state.idWarehouseforEdit = payload.id;
  },

  ///Editar Parqueo
  EDITAR_PARQUEO(state, payload) {
    state.showEditParkingModule = payload.showEdit;
    state.idParkingforEdit = payload.id;
  },

  //panel principal
  SET_SELLERS_DATA_BOOL(state, payload) {
    state.CompanyProfile.sellersData = payload;
  },

  VISUALIZADOR_NIVEL_SELECCIONADO(state, payload) {
    (state.ui.levelID = payload.levelID),
      (state.ui.level = payload.levelSelected);
  },

  ENVIAR_COMPARADOR(state, payload) {
    payload.forEach(i => {
      state.compareItems.push(i);
    });
  },

  ENVIAR_UNIDAD_A_COMPARADOR(state, payload) {
    state.compareItems.push(payload);
  },

  ENVIAR_ELEMENTO_COMPARADOR(state, payload) {
    state.compareItems.push(payload);
  },

  ELIMINAR_EN_COMPARADOR(state, payload) {
    state.compareItems.splice(payload, 1);
  },

  ACTUALIZACION_INDEPENDIENTE(state, payload) {
    payload.get().then(doc => {
      const newPayload = doc.data();
      (state.CompanyProfile.id = newPayload.ID),
        (state.CompanyProfile.adminEmail = newPayload.Admin_Email),
        (state.CompanyProfile.adminName = newPayload.Admin_Name),
        (state.CompanyProfile.adminLastName = newPayload.Admin_LastName),
        (state.CompanyProfile.cEmail = newPayload.Email_Contacto),
        (state.CompanyProfile.cPhone = newPayload.Telefono),
        (state.CompanyProfile.cName = newPayload.Desarrolladora),
        (state.CompanyProfile.cWeb = newPayload.Website),
        (state.CompanyProfile.fUrl = newPayload.FacebookUrl),
        (state.CompanyProfile.iUrl = newPayload.InstagramUrl),
        (state.CompanyProfile.lUrl = newPayload.LinkedinUrl),
        (state.CompanyProfile.cDescription = newPayload.PerfilDesarrolladora),
        (state.CompanyProfile.profileImg = newPayload.profileImg);
    });
  },

  IMAGEN_PERFIL_ACTUALIZADO(state, payload) {
    state.CompanyProfile.profileImg = payload;
    router.push({ path: "/panel-desarrolladora" });
  },

  IMAGEN_HEADER_PERFIL_ACTUALIZADO(state, payload) {
    state.CompanyProfile.profileHeaderImg = payload;
    router.push({ path: "/panel-desarrolladora" });
  },

  //// NUEVA CUENTA ////
  POSIBLE_NUEVO_USUARIO(state, payload) {
    localStorage.user_name = payload.user_name;
  },

  ///Pipedrive Token ///
  SET_PIPEDRIVE_TOKEN(state, payload) {
    state.pipedriveToken = payload;
  },

  // ////////////////////////////////////////////
  //DATOS A COTIZACION (QUOTE_DATA)
  // ////////////////////////////////////////////
  MOSTRAR_DESCUENTOS(state, payload) {
    state.quote_data.discuont_area = payload.enabled;
  },

  CLIENTE_SELECCIONADO(state, payload) {
    state.quote_data.first_name = payload.first_name;
    state.quote_data.last_name = payload.last_name;
    state.quote_data.phone = payload.phone;
    state.quote_data.email = payload.email;
    state.quote_data.id = payload.id;
  },
  TRATO_SELECCIONADO(state, payload) {
    state.quote_data.deal_id = payload.deal_id;
    state.quote_data.pipeline_id = payload.pipeline_id;
  },

  DESCUENTO_SELECCIONADO(state, payload) {
    state.quote_data.discount_amount = payload.discount_amount;
  },

  PARQUEO_SELECCIONADO(state, payload) {
    state.quote_data.parkings = payload;
  },

  BODEGA_SELECCIONADA(state, payload) {
    state.quote_data.warehouses = payload;
  },

  ENGANCHE_CLIENTE(state, payload) {
    state.quote_data.depositValues.client = payload;
  },
  CUOTAS_PERSONALIZADAS(state, payload) {
    state.quote_data.depositValues.custom_payments = payload;
  },
  PAGOS_PERSONALIZADOS(state, payload) {
    state.quote_data.depositValues.customPay = payload;
  },

  MESES_ENGANCHE(state, payload) {
    state.quote_data.depositValues.monthDeposit = payload;
  },

  FINANCIAMIENTO_BANCARIO(state, payload) {
    (state.quote_data.financingValues.entity = payload.entity),
      (state.quote_data.financingValues.interestEntity =
        payload.interestEntity),
      (state.quote_data.financingValues.yearsFinancingSelected =
        payload.yearsFinancingSelected);
  },

  AÑOS_FINANCIAMIENTO_BANCARIO(state, payload) {
    state.quote_data.financingValues.yearsFinancingSelected =
      payload.yearsFinancingSelected;
  },

  RESERVA_ESTABLECIDA(state, payload) {
    state.quote_data.financingValues.reservePrice = payload;
  },
  GASTOS_ADICIONALES(state, payload) {
    state.quote_data.additional_costs_id = payload.additional_costs_id;
    state.quote_data.additional_name = payload.additional_name;
    state.quote_data.additional_costs_value = payload.additional_costs_value;
  },

  FINANCE_AMOUNT(state, payload) {
    state.quote_data.financingValues.financeAmount = payload;
  },

  DEPOSIT_AMOUNT(state, payload) {
    state.quote_data.financingValues.totalDeposit = payload;
  },

  // VxAutoSuggest

  UPDATE_STARRED_PAGE(state, payload) {
    // find item index in search list state
    const index = state.navbarSearchAndPinList["pages"].data.findIndex(
      item => item.url == payload.url
    );

    // update the main list
    state.navbarSearchAndPinList["pages"].data[index].is_bookmarked =
      payload.val;

    // if val is true add it to starred else remove
    if (payload.val) {
      state.starredPages.push(
        state.navbarSearchAndPinList["pages"].data[index]
      );
    } else {
      // find item index from starred pages
      const index = state.starredPages.findIndex(
        item => item.url == payload.url
      );

      // remove item using index
      state.starredPages.splice(index, 1);
    }
  },

  // Navbar-Vertical

  ARRANGE_STARRED_PAGES_LIMITED(state, list) {
    const starredPagesMore = state.starredPages.slice(10);
    state.starredPages = list.concat(starredPagesMore);
  },
  ARRANGE_STARRED_PAGES_MORE(state, list) {
    let downToUp = false;
    let lastItemInStarredLimited = state.starredPages[10];
    const starredPagesLimited = state.starredPages.slice(0, 10);
    state.starredPages = starredPagesLimited.concat(list);

    state.starredPages.slice(0, 10).map(i => {
      if (list.indexOf(i) > -1) downToUp = true;
    });

    if (!downToUp) {
      state.starredPages.splice(10, 0, lastItemInStarredLimited);
    }
  },

  // ////////////////////////////////////////////
  // UI
  // ////////////////////////////////////////////

  TOGGLE_CONTENT_OVERLAY(state, val) {
    state.bodyOverlay = val;
  },
  UPDATE_PRIMARY_COLOR(state, val) {
    state.themePrimaryColor = val;
  },
  UPDATE_THEME(state, val) {
    state.theme = val;
  },
  UPDATE_WINDOW_WIDTH(state, width) {
    state.windowWidth = width;
  },
  UPDATE_WINDOW_SCROLL_Y(state, val) {
    state.scrollY = val;
  },

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  // Updates user info in state and localstorage
  setToken(state, token) {
    state.token = token;
  },
  setRoll(state, roll) {
    state.roll = roll;
  },
  SET_SETTING_MENU(state, payload) {
    
    state.setting.backgroundMenu = payload.backgroundMenu;
    state.setting.colorOptionMenu = payload.colorOptionMenu;
    state.setting.colorOptionSelected = payload.colorOptionSelected;
    state.setting.colorHeaderOption = payload.colorHeaderOption;
    state.setting.backgroundOptionSelected = payload.backgroundOptionSelected;
    state.setting.defaultColors = payload.defaultColors;
  },
  SET_LOGO_MENU(state, pathImage) {
    console.log(pathImage);
    state.setting.iconMenu = pathImage;
  },
  TOGGLE_S3_UPLOAD_MODAL(state, payload){
    state.uploadToS3Component = payload;
  }
};

export default mutations;
