const CurrentData = () => import("../views/pages/DatosActuales.vue");
const Stock = () => import("../views/pages/Inventario.vue");
const Effectiveness = () => import("../views/pages/Efectividad.vue");

export const ReportsRoutes = [
    {
        path: "/datos-actuales",
        name: "Datos Actuales",
        component: CurrentData,
        meta: { administrador: true, requireAuth: true  }
    },
    {
        path: "/inventario",
        name: "Analisis de Inventario",
        component: Stock,
        meta: { administrador: true, requireAuth: true  }
    },
    {
        path: "/efectividad",
        name: "Analisis de Efectividad",
        component: Effectiveness,
        meta: { administrador: true, requireAuth: true }
    },
];
