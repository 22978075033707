const Notifications = () => import("../views/pages/HistorialNotificaciones.vue");

export const UserRoutes = [
    {
        path: "",
        redirect: "/login"
    },
    {
        path: "/notificaciones",
        name: "Historial de notificaciones",
        component: Notifications,
        meta: { administrador: true, vendedor: true, requireAuth: true  }
    },
];
