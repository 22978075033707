const ProjectList = () => import("./pages/Proyectos.vue");
const NewProject = () => import("./pages/NewProject.vue");
const ProjectGalleries = () => import("./pages/Galerias.vue");
const NewLevel = () => import("./components/AgregarNiveles.vue");
const EditSettings = () => import("./components/EditSettings.vue");
const ImportLevel = () => import("./components/InventarioNivel.vue");

export const ProjectRoutes = [
    {
        path: "/proyectos",
        name: "Proyectos",
        component: ProjectList,
        meta: { administrador: true, requireAuth: true }
    },
    {
        path: "/formulario-de-ingreso-de-proyecto",
        name: "Ingresar Proyecto",
        component: NewProject,
        meta: { administrador: true, requireAuth: true }
    },
    {
        path: "/agregar-niveles/proyecto/:proyectID",
        name: "Agregar Niveles",
        component: NewLevel,
        meta: { administrador: true, requireAuth: true }
    },
    {
        path: "/configuraciones-de-proyecto",
        name: "Configuraciones de Proyecto",
        component: EditSettings,
        meta: { administrador: true, vendedor: true, requireAuth: true }
    },
    {
        path: "/galerias-de-proyecto/:projectId",
        name: "Galerias",
        component: ProjectGalleries,
        meta: { administrador: true, requireAuth: true }
    },
    {
        path: "/agregar-inventario/nivel/:nivelID",
        name: "Agregar Inventario a Nivel",
        component: ImportLevel,
        meta: { administrador: true, requireAuth: true }
    },
];
