const actions = {
  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu
  updateVerticalNavMenuWidth({ commit }, width) {
    commit("UPDATE_VERTICAL_NAV_MENU_WIDTH", width);
  },

  // VxAutoSuggest
  updateStarredPage({ commit }, payload) {
    commit("UPDATE_STARRED_PAGE", payload);
  },

  // The Navbar
  arrangeStarredPagesLimited({ commit }, list) {
    commit("ARRANGE_STARRED_PAGES_LIMITED", list);
  },
  arrangeStarredPagesMore({ commit }, list) {
    commit("ARRANGE_STARRED_PAGES_MORE", list);
  },

  // /////////////////////////////////////////////
  // UI
  // /////////////////////////////////////////////

  toggleContentOverlay({ commit }) {
    commit("TOGGLE_CONTENT_OVERLAY");
  },
  updateTheme({ commit }, val) {
    commit("UPDATE_THEME", val);
  },

  ////Acciones de Tarjetas en Panel Principal

  readyDataOnSellers({ commit }, payload) {
    commit("SET_SELLERS_DATA_BOOL", payload);
  },

  visualizacionNivel({ commit }, payload) {
    commit("VISUALIZADOR_NIVEL_SELECCIONADO", payload);
  },

  //Comparador
  enviar_a_comparador({ commit }, payload) {
    commit("ENVIAR_COMPARADOR", payload);
  },

  enviar_a_comparador_unidad({ commit }, payload) {
    commit("ENVIAR_UNIDAD_A_COMPARADOR", payload);
  },

  enviar_elemento_comparador({ commit }, payload) {
    commit("ENVIAR_ELEMENTO_COMPARADOR", payload);
  },

  elimnarElementoEnComparador({ commit }, payload) {
    commit("ELIMINAR_EN_COMPARADOR", payload);
  },

  ///////Editor de Usuario
  editarUsuario({ commit }, payload) {
    commit("EDITAR_USUARIO", payload);
  },

  //////Editar Nivel
  mostrarEditarNivel({ commit }, payload) {
    commit("MOSTRAR_EDITAR_NIVEL", payload);
  },

  ////Editar Bodega
  EditarBodega({ commit }, payload) {
    commit("EDITAR_BODEGA", payload);
  },

  ////Editar Parqueo
  EditarParqueo({ commit }, payload) {
    commit("EDITAR_PARQUEO", payload);
  },

  /////////////
  // DATOS PARA COTIZACION
  ////////////
  mostrar_descuento({ commit }, payload) {
    commit("MOSTRAR_DESCUENTOS", payload);
  },
  Cliente_Seleccionado({ commit }, payload) {
    commit("CLIENTE_SELECCIONADO", payload);
  },
  Gastos_Adicionales({ commit }, payload) {
    commit("GASTOS_ADICIONALES", payload);
  },
  Trato_Seleccionado({ commit }, payload) {
    commit("TRATO_SELECCIONADO", payload);
  },
  Descuento_seleccionado({ commit }, payload) {
    commit("DESCUENTO_SELECCIONADO", payload);
  },

  Parqueo_Seleccionado({ commit }, payload) {
    commit("PARQUEO_SELECCIONADO", payload);
  },

  Bodega_Seleccionada({ commit }, payload) {
    commit("BODEGA_SELECCIONADA", payload);
  },

  Enganche_Cliente({ commit }, payload) {
    commit("ENGANCHE_CLIENTE", payload);
  },

  Meses_Enganche({ commit }, payload) {
    commit("MESES_ENGANCHE", payload);
  },

  cuotas_personalizadas({ commit }, payload) {
    commit("CUOTAS_PERSONALIZADAS", payload);
  },

  Seleccion_Pagos_Personalizados({ commit }, payload) {
    commit("PAGOS_PERSONALIZADOS", payload);
  },

  Financiamiento_Bancario({ commit }, payload) {
    commit("FINANCIAMIENTO_BANCARIO", payload);
  },

  Años_Financiamiento_Bancario({ commit }, payload) {
    commit("AÑOS_FINANCIAMIENTO_BANCARIO", payload);
  },

  reserva_establecida({ commit }, payload) {
    commit("RESERVA_ESTABLECIDA", payload);
  },

  set_amount_to_finance({ commit }, payload) {
    commit("FINANCE_AMOUNT", payload);
  },

  set_total_to_deposit({ commit }, payload) {
    commit("DEPOSIT_AMOUNT", payload);
  },

  //// Crear Cuenta /////
  PosibleNuevoUsuario({ commit }, payload) {
    commit("POSIBLE_NUEVO_USUARIO", payload);
  },

  ////Pipedrive Token ////
  setPipedriveToken({ commit }, payload) {
    commit("SET_PIPEDRIVE_TOKEN", payload);
  },

  changeSettingMenu({ commit }, payload) {
    commit("SET_SETTING_MENU", payload);
  },

  changeIconMenu({ commit }, payload) {
    const pathImage = payload;
    commit("SET_LOGO_MENU", pathImage);
  }
};

export default actions;
