const OptionedView = () => import("./pages/Optioned.vue");
const OptionedByProject = () => import("./pages/OptionedByProject.vue");

export const OptionedRoutes = [
    {
        path: "/opcionados",
        name: "Opcionados",
        component: OptionedView,
        meta: { administrador: false, requireAuth: false }
    },
    {
        path: "/opcionados-en-proyecto/:id",
        name: "Opcionados por Proyecto",
        component: OptionedByProject,
        meta: { administrador: true, vendedor: true, requireAuth: true }
      },
];
