
export default {
    namespaced: true,
    state: {
        developerData:[]
    },
    getters: {
        getDataDeveloper: state => state.developerData
    },
    actions: {
        send_developer_data({commit}, payload){
            commit("SEND_DEVELOPER_DATA", payload)
        }
    },
    mutations: {
        SEND_DEVELOPER_DATA(state, payload){
            state.developerData = payload
        }
    }
}