import Vue from "vue";
import Router from "vue-router";
import store from './store/store'

import { QuoteRoutes } from './modules/quotes/router';
import { ReservationRoutes } from './modules/reservations/router';
import { OptionedRoutes } from './modules/optioned/router';
import { ProjectRoutes } from './modules/projects/router';
import { PropertyRoutes } from './modules/properties/router';
import { DeveloperRoutes } from './modules/developer/router';
import { UserRoutes } from './routes/User';
import { SearchRoutes } from './routes/Search';
import { ReportsRoutes } from './routes/Reports';
import { AuthRoutes } from './views/pages/auth/auth.router';
import { TeamRoutes } from './modules/users/router';
Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    ...AuthRoutes,
    {
      path: "/",
      redirect: "/login"
    },
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        // =============================================================================
        // Theme Routes
        // ============================================================================

        ...UserRoutes,
        ...QuoteRoutes,
        ...ReservationRoutes,
        ...OptionedRoutes,
        ...ProjectRoutes,
        ...DeveloperRoutes,
        ...PropertyRoutes,
        ...SearchRoutes,
        ...ReportsRoutes,
        ...TeamRoutes

      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: "/activar-app/:auth?",
          name: "Pipedrive-app-activate",
          component: () => import("./views/pages/ActivatePipedriveApp.vue"),
          meta: { requireAuth: false }
        },

        {
          path: "/404",
          name: "page-error-404",
          component: () => import("@/views/pages/Error404.vue"),
          meta: { requireAuth: false }
        },
        {
          path: "/create-account",
          name: "creacion-cuenta",
          component: () => import("@/views/pages/CreacionCuenta.vue"),
          meta: { requireAuth: false }
        },
        {
          path: "/confirmation-account-created:Id",
          name: "success-account",
          component: () => import("@/views/pages/Confirmation.vue"),
          meta: { requireAuth: false }
        },
        {
          path: "/terms-and-conditions",
          name: "terms",
          component: () => import("@/views/pages/TermsConditions.vue"),
          meta: { requireAuth: false }
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: "*",
      redirect: "/404"
    },
  ]
});

/*Check if need Auth*/
router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth && !store.getters['auth/isLoggedIn']){
    next({
      name: 'Login',
      query: {
        from: to.fullPath
      }
    });
  } else if(store.getters['auth/isLoggedIn'] && to.fullPath == '/') {
    next('/panel-desarrolladora');
  } else if(!store.getters['auth/isLoggedIn'] && to.fullPath == '/') {
    next({
      name: 'Login',
    });
  } 

  next();
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
