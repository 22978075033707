import gql from "graphql-tag";
import graphqlClient from "../graphqlClient";

const loginAnonymously = async () => {
  const loginResponse = await graphqlClient.mutate({
    mutation: gql`
      mutation login {
        loginAnonymously {
          access_token
        }
      }
    `,
  });

  return loginResponse.data.loginAnonymously;
};

const login = async (email, password) => {
  const loginResponse = await graphqlClient.mutate({
    mutation: gql`
      mutation login($email: String!, $password: String!) {
        login(email: $email, password: $password) {
          access_token
          account_id
        }
      }
    `,
    variables: {
      email: email,
      password: password,
    },
  });

  return loginResponse.data.login;
};

const loginSSO = async (token) => {
  const loginResponse = await graphqlClient.mutate({
    mutation: gql`
      mutation loginSSO($token: String!) {
        loginSSO(token: $token) {
          access_token
          account_id
        }
      }
    `,
    variables: {
      token: token
    },
    onError: function(err) {
      console.log(err);
    },
    onCompleted: function(data) {
      console.log(data);
    },
  });

  return loginResponse.data.loginSSO;
}

const resetPassword = async (password, token) => {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation resetPassword($password: String!, $token: String!) {
        resetPassword(password: $password, token: $token) {
          response
        }
      }
    `,
    variables: {
      password,
      token,
    },
  });

  return response;
};

const updatePassword = async (token, password) => {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation updatePassword($token: String!, $password: String!) {
        updatePassword(token: $token, password: $password)
      }
    `,
    variables: {
      token: token,
      password: password,
    },
  });

  return response.data.updatePassword;
};

export default {
  login,
  loginSSO,
  loginAnonymously,
  resetPassword,
  updatePassword,
};
