import gql from "graphql-tag";
import graphqlClient from "../graphqlClient";

const list = async () => {
  const res = await graphqlClient.query({
    query: gql`
      {
        bank {
          id
          name
          default_interest_rate
          max_years
        }
      }
    `
  });

  return res.data.bank;
};

const listByCountry = async countryID => {
  const res = await graphqlClient.query({
    query: gql`
      query getBankByCountry($countryID: uuid!) {
        bank(where: { country_id: { _eq: $countryID } }) {
          id
          name
          max_years
          default_interest_rate
        }
      }
    `,
    variables: {
      countryID
    }
  });
  return res.data.bank;
};

export default {
  list,
  listByCountry
};
