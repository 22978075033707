import Vue from "vue";
import App from "./App.vue";

//sentry
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

const sentryDsn = process.env.VUE_APP_SENTRY_DSN || false;
// "https://6e8a5d591958460ab2dd898b38ec1440@o4504568052056064.ingest.sentry.io/4504572035137536";
Sentry.init({
  Vue,
  dsn: sentryDsn,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: [
        "localhost",
        "*.flattlo.com",
        "*.uncierre.com",
        /^\//,
      ],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

export const bus = new Vue();

//iframeModule
import VueFriendlyIframe from "vue-friendly-iframe";
Vue.use(VueFriendlyIframe);

//vee-validate
import es from "vee-validate/dist/locale/es";
import VeeValidate, { Validator } from "vee-validate";
Vue.use(VeeValidate);
Validator.localize("es", es);

import dotenv from "dotenv";
dotenv.config();

//Vue e-sign
import vueEsign from "vue-esign";
Vue.use(vueEsign);

//Vuetify
import Vuetify, {
  VDataTable,
  VIcon,
  VChip,
  VCard,
  VSimpleCheckbox,
  VMenu,
  VBtn,
  VTextField,
  VApp,
  VSelect,
  VDataFooter,
  VStepper,
  VStepperHeader,
  VStepperStep,
  VDivider,
  VDatePicker,
  VRadioGroup,
  VRadio,
} from "vuetify/lib";
import { Ripple } from "vuetify/lib/directives";

Vue.use(Vuetify, {
  components: {
    VDataTable,
    VIcon,
    VChip,
    VCard,
    VSimpleCheckbox,
    VMenu,
    VBtn,
    VTextField,
    VApp,
    VSelect,
    VDataFooter,
    VStepper,
    VStepperHeader,
    VStepperStep,
    VDivider,
    VDatePicker,
    VRadioGroup,
    VRadio,
  },
  directives: {
    Ripple,
  },
});

// Vuesax Component Framework
import Vuesax from "vuesax";
import "material-icons/iconfont/material-icons.css"; //Material Icons
import "vuesax/dist/vuesax.css"; // Vuesax
Vue.use(Vuesax);

//Vue2Storage
import { Vue2Storage } from "vue2-storage";
Vue.use(Vue2Storage);

// Theme Configurations
import "../themeConfig.js";

// Globally Registered Components
import "./globalComponents.js";

// Styles: SCSS
import "./assets/scss/main.scss";

// Tailwind
import "@/assets/css/main.css";

// Vue Router
import router from "./router";

//Vue loading skeleton
import Skeleton from "vue-loading-skeleton";
Vue.use(Skeleton);

// Vuex Store
import store from "./store/store";

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from "vue2-hammer";
Vue.use(VueHammer);

// PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";

// Feather font icon
require("./assets/css/iconfont.css");

//Clipboard
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

//Vue Google maps
import * as VueGoogleMaps from "vue2-google-maps";
const googleMapsKey = process.env.VUE_APP_GMAPS_KEY;

Vue.use(VueGoogleMaps, {
  load: {
    key: googleMapsKey,
    libraries: "places", // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
});

// import Quote from 'flattlo-quotation';
// Vue.use(Quote, { router });

// i18n
import VueI18n from "vue-i18n";
import lang from "./lang/lang.js";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "es",
  messages: lang,
});

//Import ag-grid-vue
import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
import "../node_modules/ag-grid-community/dist/styles/ag-theme-material.css";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";

Vue.config.productionTip = false;

const opts = {
  icons: {
    iconfont: "md",
  },
};

new Vue({
  i18n,
  router,
  store,
  vuetify: new Vuetify(opts),
  render: (h) => h(App),
}).$mount("#app");