const optionedStore = {
  namespaced: true,
  state: {
    optionedSelected: null,
    optionedSelectedDataTable: [],
    modalConfirmationOptioned: false
  },
  getters: {},
  actions: {},
  mutations: {
    selectionfromOptionedSelector(state, payload) {
      state.optionedSelected = payload;
    },
    dataFromOptionedTable(state, payload) {
      state.optionedSelectedDataTable = payload;
    },
    modalConfirmationOptioned(state, paylaod) {
      state.modalConfirmationOptioned = paylaod;
    }
  }
};

export default optionedStore;
