const LoginComponent = () => import("./Login.vue");
const RestorePwdComponent = () => import("./RestorePass.vue");
const RecoverPassComponent = () => import("./RecoverPass.vue");



export const AuthRoutes = [
  {
    path: "",
    component: () => import("@/layouts/full-page/FullPage.vue"),
    children: [
      // =============================================================================
      // PAGES
      // =============================================================================
      {
        path: "/login",
        name: "Login",
        component: LoginComponent,
        meta: { requireAuth: false }
      },
      {
        path: "/restore-pass:Id",
        name: "RestorePass",
        component: RestorePwdComponent,
        meta: { requireAuth: false }
      },
      {
        path: "/recover-pass",
        name: "RestorePass",
        component: RecoverPassComponent,
        meta: { requireAuth: false }
      },
    ]
  }
];
