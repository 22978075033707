const propertyStore = {
  namespaced: true,
  state: {
    selectedPropertyId: localStorage.getItem("selectedPropertyId") || null,
    compareList: []
  },
  getters: {
    selectedPropertyId: state => state.selectedPropertyId,
    compareList: state => {
      return state.compareList;
    }
  },
  actions: {
    setSelectedPropertyId({ commit }, { propertyId }) {
      commit("setSelectedProperty", {
        propertyId
      });
    },
    setCompareList({ commit, dispatch }, payload) {
      commit("updateCompareList", payload);
      dispatch("updateCompareListOnStorage", payload);
    },
    updateCompareListOnStorage({ state }) {
      const list = JSON.stringify(state.compareList);
      localStorage.setItem("compareList", list);
    },
    updateCompareListOnPropertyModule({ commit }, payload) {
      commit("updateCompareListOnPropertyModule", payload);
    },
    removeItemFromCompareList({ commit, dispatch }, payload) {
      commit("removeItemFromCompareList", payload);
      dispatch("updateCompareListOnStorage", payload);
    }
  },
  mutations: {
    setSelectedProperty(state, payload) {
      state.selectedPropertyId = payload.propertyId;
      localStorage.selectedPropertyId = state.selectedPropertyId;
    },
    updateCompareList(state, payload) {
      state.compareList.push(payload);
    },
    updateCompareListOnPropertyModule(state, payload) {
      state.compareList = payload;
    },
    removeItemFromCompareList(state, payload) {
      state.compareList = state.compareList.filter(o => o.id !== payload);
    }
  }
};

export default propertyStore;
