const Search = () => import("../views/pages/BuscadorAvanzado.vue")
const Compare = () => import("../views/pages/Comparador.vue");

export const SearchRoutes = [
    {
        path: "/buscador",
        name: "Buscador Avanzado",
        component: Search,
        meta: { administrador: true, vendedor: true, requireAuth: true }
    },
    {
        path: "/comparador",
        name: "Comparador",
        component: Compare,
        meta: { administrador: true,vendedor: true, requireAuth: true }
    }
];
