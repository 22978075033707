/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import navbarSearchAndPinList from "@/layouts/components/navbar/navbarSearchAndPinList"
import themeConfig from "@/../themeConfig.js"
import colors from "@/../themeConfig.js"


// /////////////////////////////////////////////
// Variables
// /////////////////////////////////////////////

// const userDefaults = {
//   uid         : 0,          // From Auth
//   displayName : "John Doe", // From Auth
//   about       : "Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.",
//   photoURL    : require("@/assets/images/portrait/small/avatar-s-11.jpg"), // From Auth
//   status      : "online",
//   userRole    : "admin"
// }

// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
    token                   : null,
    pipedriveToken          : '',
    bodyOverlay             : false,
    isVerticalNavMenuActive : true,
    mainLayoutType          : themeConfig.mainLayoutType || "vertical",
    navbarSearchAndPinList  : navbarSearchAndPinList,
    reduceButton            : themeConfig.sidebarCollapsed,
    verticalNavMenuWidth    : "default",
    verticalNavMenuItemsMin : false,
    scrollY                 : 0,
    starredPages            : navbarSearchAndPinList["pages"].data.filter((page) => page.is_bookmarked),
    theme                   : themeConfig.theme || "light",
    themePrimaryColor       : colors.primary,
    user:'',
    error: '',
    editUserID: '',
    showEditModule: false,
    idLevelforEdit: 0,
    showEditApartamentModule: false,
    idApartamentforEdit: 0,
    showEditWarehouseModule: false,
    idWarehouseforEdit: 0,
    showEditParkingModule: false,
    idParkingforEdit: 0,
    uploadToS3Component: null,
    quote_data:{
      discuont_area: false,
      first_name:'---',
      last_name: '---',
      phone: '---',
      email: '---',
      id:'---',
      deal_id: '---',
      pipeline_id: '---',
      discount_amount:0,
      additional_costs_id: '---',
      additional_name: '---',
      additional_costs_value: 0,
      parkings:[],
      warehouses:[],
      depositValues:{
          client:0,
          monthDeposit: 0,
          customPay: false,
          custom_payments: []
      },
      financingValues:{
          entity:'',
          interestEntity: 0,
          yearsFinancingSelected: 0,
          reservePrice:0,
          financeAmount: 0,
          totalDeposit: 0
      }
  },
  ui:{
      proyectID: '',
      proyectName: '',
      levelID:'5db1fe83790dc5220037f041',
      level:''
  },
  setting: {
    iconMenu: 'https://flattlo-app.s3.amazonaws.com/base-stock-images/flattlo3.png',
    backgroundMenu: "#1155CC",
    colorHeaderOption: "#F1C232",
    colorOptionMenu: "#ffffff",
    colorOptionSelected: "#434343",
    backgroundOptionSelected: "#f1c232",
    defaultColors: false
  },
  compareItems:[],
  CompanyProfile:{
      adminEmail: '',
      adminName: '',
      adminLastName: '',
      id:'',
      cEmail: '',
      cPhone: '',
      cName: '',
      cWeb: '',
      cDescription: '',
      fUrl: '',
      iUrl: '',
      lUrl: '',
      webUrl: '',
      profileImg: '',
      profileHeaderImg: '',
      sellersData: false,


  },
    // Can be used to get current window with
    // Note: Above breakpoint state is for internal use of sidebar & navbar component
    windowWidth: null
}

export default state
