const reservationStore = {
  namespaced: true,
  state: {
    reservationId: null,
    modalPayment: false,
    modalPaymentDetail: false,
    modalPaymentEdit: false,
    payment: null,
    paymentType: null,
    paymentDescription:null,
    paymentAmount: 0,
    paymentPaid: 0,
    paymentPending: 0,
    paymentId: 0
  },
  getters: {},
  actions: {},
  mutations: {
    setReservationId(state, payload) {
      state.reservationId = payload;
    },
    setPayment(state, payload) {
      state.payment = payload;
    },
    setPaymentType(state, payload) {
      state.paymentType = payload;
    },
    setPaymentDescription(state, payload) {
      state.paymentDescription = payload;
    },
    setPaymentAmount(state, payload) {
      state.paymentAmount = payload;
    },
    setModalPayment(state, payload) {
      state.modalPayment = payload;
    },
    setModalPaymentDetail(state, payload){
      state.modalPaymentDetail = payload;
    },
    setModalPaymentEdit(state, payload){
      state.modalPaymentEdit = payload;
    },
    setPaymentPaid(state, payload){
      state.paymentPaid = payload;
    },
    setPaymentPending(state, payload){
      state.paymentPending = payload;
    },
    setPaymentId(state, payload){
      state.paymentId = payload;
    },
  }
};

export default reservationStore;
