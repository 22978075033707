const MainPanel = () => import("./pages/PanelPrincipal.vue");
const Profile = () => import("./pages/Profile.vue");

export const DeveloperRoutes = [
  {
    // Should be 'cotizar-proyecto'
    path: "/panel-desarrolladora",
    name: "Dashboard",
    component: MainPanel,
    meta: { requireAuth: true }
  },
  {
    path: "/perfil-empresarial",
    name: "Perfil Empresarial",
    component: Profile,
    meta: { administrador: true, requireAuth: true }
  }
];