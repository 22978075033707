import gql from "graphql-tag";
import graphqlClient from "../graphqlClient";

const sendEmail = async (type, body) => {
    const response = await graphqlClient.mutate({
      mutation: gql`
      mutation sendEmail($emailBody: emailBody!) {
        sendEmail(emailBody: $emailBody) {
          response
        }
      }
      `,
      variables: {
        emailBody:{
           type,
           body
        }
      }
    });
    return response;
  };

  export default {
    sendEmail
  };