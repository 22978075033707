import { auth as authService } from "../services";
import { account as accountService } from "../services";

const auth = {
  namespaced: true,
  state: {
    user: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null,
    jwt: localStorage.getItem("JWT") || ""
  },
  getters: {
    isLoggedIn: state => Boolean(state.jwt && state.user),
    getUser: state => state.user
  },
  actions: {
    async login({ commit }, { email, password }) {
      const loginAttempt = await authService.login(email, password);
      commit("setToken", loginAttempt.access_token);

      const account = await accountService.get(loginAttempt.account_id);
      commit("authSuccess", {
        token: loginAttempt.access_token,
        account
      });

      return account;
    },
    async loginSSO({ commit }, { token }) {
      const loginAttempt = await authService.loginSSO(token);
      commit("setToken", loginAttempt.access_token);

      const account = await accountService.get(loginAttempt.account_id);
      commit("authSuccess", {
        token: loginAttempt.access_token,
        account
      });

      return account;
    },
    logout() {
      localStorage.removeItem("JWT");
      localStorage.removeItem("user");
      return;
    }
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      localStorage.JWT = state.token;
    },
    authSuccess(state, payload) {
      const account = payload.account;
      const user = payload.account.user;
      const developer = payload.account.property_developer;
      const role = payload.account.account_roles
        ? payload.account.account_roles[0].role
        : null;

      state.user = {
        id: account.id,
        status: account.active,
        email: account.email,
        userId: user.id,
        firstName: user.first_name,
        lastName: user.last_name,
        pic: user.avatar,
        roleId: role ? role.id : null,
        role: role ? role.name : null,
        developerId: developer ? developer.id : null,
        developerName: developer ? developer.name : null,
        countryId: developer ? developer.country.id : null,
        country: developer ? developer.country.name : null,
        currencyName: developer
          ? developer.country.country_currencies[0].currency.name
          : null,
        countryCode: developer
          ? developer.country.code
          : null,
        phone: user.phone ? user.phone : null,
        userProject: account.user.user_projects
          ? account.user.user_projects
          : null
      };
      localStorage.user = JSON.stringify(state.user);
    },
    logout(state) {
      localStorage.clear();
      state.token = null;
      state.user = null;
    }
  }
};

export default auth;
