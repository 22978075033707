/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Vuex from "vuex";


import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

import auth from "./auth.module";
import developer from "./developer.module";
import project from "../modules/projects/project.store";
import optioned from "../modules/optioned/optioned.store";
import reservation from "../modules/reservations/reservation.store";
import property from "../modules/properties/property.store";

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  modules: {
    auth,
    developer,
    project,
    optioned,
    property,
    reservation
  },
  getters,
  mutations,
  state,
  actions,
  strict: process.env.NODE_ENV !== "production",
 
});
