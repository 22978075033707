const ReservationsList = () => import("./pages/Reservas.vue");
const ReservationsByProject = () => import("./pages/ReservationByProject.vue");
const Payments = () => import("./pages/Payments.vue");
const ClientPortfolio = () => import("./pages/ClientPortfolio.vue");

export const ReservationRoutes = [
  {
    path: "/reservas",
    name: "ReservasGeneradas",
    component: ReservationsList,
    meta: { administrador: true, vendedor: true, requireAuth: true },
  },
  {
    path: "/reservas-en-proyecto/:id",
    name: "ReservasGeneradas por Proyecto",
    component: ReservationsByProject,
    meta: { administrador: true, vendedor: true, requireAuth: true },
  },
  {
    path: "/pagos/:reservationId",
    name: "Pagos",
    component: Payments,
    meta: { administrador: true, vendedor: true, requireAuth: true },
  },
  {
    path: "/cartera/:id",
    name: "Cartera",
    component: ClientPortfolio,
    meta: { administrador: true, vendedor: true, requireAuth: true },
  },
];