import gql from "graphql-tag";
import graphqlClient from "../graphqlClient";

const get = async accountId => {
  const response = await graphqlClient.query({
    query: gql`
      query account {
          account(where: {id: {_eq:"${accountId}"}}) {
              id
              email
              active
              last_login
              property_developer {
                  id
                  name
                  country {
                    id
                    name
                    code
										country_currencies {
											currency {
												id
												name
												code
												symbol
											}
										}
                  }
			        }
			        account_roles {
			            role {
			                id
			                name
			            }
			        }
			        user {
						      id
			            first_name
			            last_name
			            avatar
			            phone
                  user_projects {
                    id
                    project_id
                  }
			        }
			    }
			}
`
  });

  return response.data.account[0];
};

const createAccount = async (
  first_name,
  last_name,
  area_code,
  phone,
  email,
  pipedriveId,
  pass,
  rId
) => {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation(
        $email: String!
        $pipedriveId: String
        $fN: String!
        $lN: String!
        $rID: ID!
        $aC: String!
        $phone: String!
        $pass: String!
      ) {
        createAccount(
          createAccountInput: {
            email: $email
            first_name: $fN
            password: $pass
            role_id: $rID
            phone: $phone
            last_name: $lN
            area_code: $aC
            pipedrive_id: $pipedriveId
          }
        ) {
          id
          email
          active
          user_id
        }
      }
    `,
    variables: {
      fN: first_name.toString(),
      lN: last_name.toString(),
      aC: area_code.toString(),
      phone: phone.toString(),
      email: email.toString(),
      pass: pass.toString(),
      rID: rId.toString(),
      pipedriveId: pipedriveId
    }
  });

  return response.data.createAccount;
};

const addAccountToDeveloper = async (userID, developerID) => {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation relateAccount($userID: uuid!, $developerID: uuid!) {
        update_account_by_pk(
          _set: { property_developer_id: $developerID }
          pk_columns: { id: $userID }
        ) {
          id
        }
      }
    `,
    variables: {
      userID: userID.toString(),
      developerID: developerID.toString()
    }
  });

  return response.data.update_account_by_pk;
};

const getRol = async param => {
  const response = await graphqlClient.query({
    query: gql`
      query getRol($param: String!) {
        role(where: { name: { _eq: $param } }) {
          id
          name
        }
      }
    `,
    variables: {
      param
    }
  });
  return response.data.role;
};

export default {
  get,
  createAccount,
  addAccountToDeveloper,
  getRol
};
