export default {
  co: {
    message: {
      parking: "parqueadero | parqueaderos",
      warehouse: "cuarto útil | cuartos útiles",
      downpayment: "cuota inicial",
      reservation: "separación | separaciones",
      optioned: "opcionado | opcionados",
      apartment: "apartamento | apartamentos",
      financing: "financiamiento | financiamientos",
      Parking: "@.capitalize:message.parking",
      Warehouse: "@.capitalize:message.warehouse",
      Downpayment: "@.capitalize:message.downpayment",
      Reservation: "@.capitalize:message.reservation",
      Apartment: "@.capitalize:message.apartment",
      quote: "cotización | cotizaciones",
      Quote: "@.capitalize:message.quote",
      personalIdentityNumber: "DNI",
      taxNumberId: "NIT",
      customerId: "Identificador",
      Financing: "@.capitalize:message.financing",
      Optioned: "@.capitalize:message.optioned",
    },
  },
  gt: {
    message: {
      parking: "parqueo | parqueos",
      warehouse: "bodega | bodegas",
      downpayment: "enganche",
      reservation: "reserva | reservas",
      optioned: "opcionado | opcionados",
      apartment: "apartamento | apartamentos",
      financing: "Saldo contra entrega | Saldos contra entrega",
      Parking: "@.capitalize:message.parking",
      Warehouse: "@.capitalize:message.warehouse",
      Downpayment: "@.capitalize:message.downpayment",
      Reservation: "@.capitalize:message.reservation",
      Apartment: "@.capitalize:message.apartment",
      quote: "cotización | cotizaciones",
      Quote: "@.capitalize:message.quote",
      personalIdentityNumber: "CUI",
      taxNumberId: "NIT",
      customerId: "Identificador",
      Financing: "@.capitalize:message.financing",
      Optioned: "@.capitalize:message.optioned",
    },
  },
  mx: {
    message: {
      parking: "cajón | cajones",
      warehouse: "bodega | bodegas",
      downpayment: "enganche",
      reservation: "apartado | apartados",
      optioned: "opcionado | opcionados",
      apartment: "departamento | departamentos",
      financing: "financiamiento | financiamientos",
      Parking: "@.capitalize:message.parking",
      Warehouse: "@.capitalize:message.warehouse",
      Downpayment: "@.capitalize:message.downpayment",
      Reservation: "@.capitalize:message.reservation",
      Apartment: "@.capitalize:message.apartment",
      quote: "cotización | cotizaciones",
      Quote: "@.capitalize:message.quote",
      personalIdentityNumber: "INE",
      taxNumberId: "RFC",
      customerId: "Identificador",
      Financing: "@.capitalize:message.financing",
      Optioned: "@.capitalize:message.optioned",
    },
  },
  us: {
    message: {
      parking: "parking | parkings",
      warehouse: "warehouse | warehouses",
      downpayment: "downpayment",
      reservation: "reservation | reservations",
      optioned: "optioned | optioned",
      apartment: "apartment | apartments",
      financing: "financing | financings",
      Parking: "@.capitalize:message.parking",
      Warehouse: "@.capitalize:message.warehouse",
      Downpayment: "@.capitalize:message.downpayment",
      Reservation: "@.capitalize:message.reservation",
      Apartment: "@.capitalize:message.apartment",
      quote: "quote | quotes",
      Quote: "@.capitalize:message.quote",
      personalIdentityNumber: "ID",
      taxNumberId: "TAX ID",
      Financing: "@.capitalize:message.financing",
      Optioned: "@.capitalize:message.optioned",
    },
  },
  py: {
    message: {
      parking: "estacionamiento | estacionamientos",
      warehouse: "bodega | bodegas",
      downpayment: "enganche",
      reservation: "reserva | reservas",
      optioned: "opcionado | opcionados",
      apartment: "apartamento | apartamentos",
      financing: "financiamiento | financiamientos",
      Parking: "@.capitalize:message.parking",
      Warehouse: "@.capitalize:message.warehouse",
      Downpayment: "@.capitalize:message.downpayment",
      Reservation: "@.capitalize:message.reservation",
      Apartment: "@.capitalize:message.apartment",
      quote: "cotización | cotizaciones",
      Quote: "@.capitalize:message.quote",
      personalIdentityNumber: "CIC",
      taxNumberId: "RUC",
      customerId: "Identificador",
      Financing: "@.capitalize:message.financing",
      Optioned: "@.capitalize:message.optioned",
    },
  },
};
