const ChooseLevel = () => import("./pages/SeleccionNivel.vue");
const QuotesList = () => import('./pages/Cotizaciones.vue');
const QuotesMenu= () => import('./pages/Cotizaciones-menu.vue');
const AcceptedQuotes= () => import("./components/Aceptadas.vue");

const NewQuote = () => import("./pages/GenerarCotizacion.vue");
const ShowQuote= () => import("./components/Visualizador.vue");
const QuoteCheck = () => import("./pages/QuoteCheck.vue");
const SingleQuote = () => import("./pages/SingleQuote.vue");

//const QuoteAuthorizations = () => import("./components/Autorizaciones.vue");
const SpecialRequests = () => import("./pages/Cotizaciones-especiales.vue");

/* Viejo reserva */
//const QuoteToReservation = () => import("./pages/QuoteToReservation.vue");
/* Nueva reserva */
const QuoteToReservation = () => import("./pages/QuoteToReservationV2.vue");

export const QuoteRoutes = [
    {
        path: "/seleccion-de-nivel",
        name: "Seleccion de Nivel",
        component: ChooseLevel,
        meta: { administrador: true, vendedor: true, requireAuth: true}
    },
    {
        path: "/cotizaciones",
        name: "Cotizaciones Generadas",
        component: QuotesMenu,
        meta: { administrador: true, vendedor: true, requireAuth: true }
    },
    {
        path: "/cotizaciones-en-proyecto/:id",
        name: "CotizacionesGeneradas por Proyecto",
        component: QuotesList,
        meta: { administrador: true, vendedor: true, requireAuth: true }
    },
    {
        path: "/cotizaciones-aceptadas",
        name: "Cotizaciones Aceptadas",
        component: AcceptedQuotes,
        meta: { administrador: true,vendedor: true, requireAuth: true }
    },
    {
        path: "/generar-cotizacion",
        name: "Generar Cotización",
        component: NewQuote,
        meta: { administrador: true, vendedor: true, requireAuth: true }
    },
    {
        path: "/visualizacion-cotizacion",
        name: "Visualizador de Cotización",
        component: ShowQuote,
        meta: { administrador: true, vendedor: true, requireAuth: true }
    },
    {
        //This is for internal use
        path: "/quotes/:Id",
        name: "Quote Check",
        component: QuoteCheck,
        meta: { administrador: true, vendedor: true, requireAuth: true }
    },
    {
        path: "/cotizaciones/:id",
        name: "Cotización",
        component: SingleQuote,
        meta: {administrador: true, vendedor: true, requireAuth: true}
    },
    // {
    //     path: "/autorizaciones",
    //     name: "Autorizaciones",
    //     component: QuoteAuthorizations,
    //     meta: { administrador: true, vendedor: true, requireAuth: true }
    // },
    {
        path: "/solicitudes-especiales",
        name: "Solicitudes Especiales",
        component: SpecialRequests,
        meta: { administrador: true, vendedor: true, requireAuth: true }
    },
    {
        path: "/cotizacion/:id/reserva",
        name: "Quote to Reservation",
        component: QuoteToReservation,
        meta: { administrador: true, vendedor: true, requireAuth: true }
    },
];