const PropertyList = () => import("./pages/Properties.vue");
const ParkingList = () => import("./pages/Parkings.vue");
const WarehouseList = () => import("./pages/Warehouses.vue");
const ShowProperty = () => import("./pages/Property.vue");

const CreateProperty = () => import("./components/CreateProperty.vue");
const EditProperties = () => import("./components/EditProperties.vue");

const ImportProperties = () =>
  import("./components/ImportadorMasivoApartamentos.vue");
const ImportAssignedParkings = () =>
  import("./components/ImportadorMasivoConAsignacion.vue");
const ImportWarehouses = () =>
  import("./components/ImportadorMasivoBodegas.vue");
const ListImagesUnitType = () => import("./components/ListImagesUnitType.vue");
const ImportAssignedWarehouse = () =>
  import("./components/ImportadorMasivoBodegaAsignacion.vue");
const UpdateUnitTypeForm = () => import("./components/UpdateUnitTypeForm.vue");
export const PropertyRoutes = [
  {
    path: "/propiedades",
    name: "Propiedades",
    component: PropertyList,
    meta: { administrador: true, requireAuth: true }
  },
  {
    path: "/parqueos/:projectId",
    name: "Parqueos",
    component: ParkingList,
    meta: { administrador: true, requireAuth: true }
  },
  {
    path: "/bodegas/:projectId",
    name: "Bodegas",
    component: WarehouseList,
    meta: { administrador: true, requireAuth: true }
  },
  {
    path: "/apartamento/:Id",
    name: "Apartamento",
    component: ShowProperty,
    meta: { administrador: true, vendedor: true, requireAuth: true }
  },
  {
    path: "/agregar-propiedades/proyecto/:projectId",
    name: "Agregar Propiedades",
    component: EditProperties,
    meta: { administrador: true, requireAuth: true }
  },
  {
    path: "/listado-de-imagenes-por-tipo/",
    name: "Listado de Imagenes Tipo",
    component: ListImagesUnitType,
    meta: { administrador: true, requireAuth: true }
  },
  {
    path: "/importador-de-imagenes-por-tipo/:projectUnitTypeId",
    name: "Importador de Imagenes",
    component: UpdateUnitTypeForm,
    meta: { administrador: true, requireAuth: true }
  },
  {
    path: "/crear-apartamento/",
    name: "Crear Apartamento",
    component: CreateProperty,
    meta: { administrador: true, requireAuth: true }
  },
  {
    path: "/importador-de-parqueos-con-asignacion/",
    name: "Importador de Parqueos con asignacion",
    component: ImportAssignedParkings,
    meta: { administrador: true, requireAuth: true }
  },
  {
    path: "/importador-de-bodegas/",
    name: "Importador de Bodegas",
    component: ImportWarehouses,
    meta: { administrador: true, requireAuth: true }
  },
  {
    path: "/importador-de-apartamentos/",
    name: "Importador de Apartamentos",
    component: ImportProperties,
    meta: { administrador: true, requireAuth: true }
  },
  {
    path: "/importador-de-bodegas-con-asignacion/",
    name: "Importador de Bodegas con asignacion",
    component: ImportAssignedWarehouse,
    meta: { administrador: true, requireAuth: true }
  }
];
