function getPayload(jwt) {
  return JSON.parse(atob(jwt.split(".")[1]));
}

function doesJWTHasExpired(payload) {
  const expiration = new Date(payload.exp * 1000);
  const now = new Date();

  if (expiration.getTime() < now.getTime()) return true;

  return false;
}

export default {
  getPayload,
  doesJWTHasExpired
};
