const ListAllTeamMembers = () => import("./pages/EquipoComercial.vue");
const Profile = () => import("./pages/Perfil.vue");

export const TeamRoutes = [
    {
        path: "/equipo-comercial",
        name: "Equipo Comercial",
        component: ListAllTeamMembers,
        meta: { administrador: true, requireAuth: true  }
    },
    {
        path: "/su-perfil",
        name: "Perfil",
        component: Profile,
        meta: { administrador: true, vendedor: true, requireAuth: true  }
    },
];