import gql from "graphql-tag";
import graphqlClient from "../graphqlClient";

const list = async () => {
  const response = await graphqlClient.query({
    query: gql`
      query {
        country {
          name
          id
          code
          telephone_prefix
          cities {
            id
            name
          }
        }
      }
    `
  });

  return response.data.country;
};

const getCountries = async () => {
  const response = await graphqlClient.query({
    query: gql`
      query {
        country(where: {_not: {name: {_eq: "Estados Unidos"}}}) {
          name
          id
          code
          telephone_prefix
          cities {
            id
            name
          }
        }
      }
    `
  });

  return response.data.country;
};

const getZones = async cityId => {
  const res = await graphqlClient.query({
    query: gql`
    {
			city_zone(where: {city_id: {_eq:"${cityId}"}}) {
				id
				name
				suburbs {
					id
					name
				}
			}
		} 
    `
  });

  return res.data.city_zone;
};

const get = async countryId => {
  const res = await graphqlClient.query({
    query: gql`
    query {
      country (where: {
        id: {
          _eq: "${countryId}"
        }
      }){
        name
        id
        code
        telephone_prefix
        cities {
          id
          name
        }
      }
    }
    `
  });

  return res.data.country ? res.data.country[0] : null;
};

const getCurrencies = async countryId => {
  const res = await graphqlClient.query({
    query: gql`
      query getCurrencies($countryId: uuid!) {
        country_currency(where: { country_id: { _eq: $countryId } }) {
          id
          country_id
          currency {
            id
            name
            code
            symbol
          }
        }
      }
    `,
    variables: {
      countryId
    }
  });
  return res.data.country_currency;
};

export default {
  getCountries,
  getZones,
  get,
  getCurrencies,
  list
};
